@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/light';

.case-list {
  $breakpoint: 950px; // NOTE: Must be the same breakpoint as menu.scss

  &__button {
    display: flex;
    justify-content: center;
    gap: spacing.$m;
    position: fixed;
    bottom: 0;
    background-color: light.$surface-primary-default;
    width: 100%;
    padding: spacing.$m 0;
    z-index: 10;
    box-shadow: 0px -8px 20px 0px rgba(0, 0, 0, 0.1);

    @media (min-width: $breakpoint) {
      left: 250px;
      padding-right: 250px;
    }
  }

  &__search-results {
    margin-bottom: spacing.$m;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: spacing.$l;
    align-items: center;
    margin-bottom: spacing.$l;
  }
}
